import { Alert, Avatar, Button, Checkbox, Form, Input, message, Modal, Select, Space, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, headerConfig } from '../../../../../Helpers';
import { IDiscount } from '../../../../../Models';
import DatePicker from 'react-datepicker2';
import 'react-quill/dist/quill.snow.css';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IDiscount,
}

const CreateDiscount = ({ getData, open, setOpen, record }: IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [type, setType] = useState(0);
    const [limitation, setLimitation] = useState(0);

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
            }
            else {
                form.resetFields();
                setType(0);
                setLimitation(0);
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {
        form.validateFields().then(() => {
            setLoading(true);
            const headerRequest = headerConfig();
            const values = form.getFieldsValue();
            axios({
                url: getApiUrl(`/discount/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: values,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد تخفیف جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد تخفیف جدید' : `ویرایش تخفیف (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createDiscount"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان تخفیف"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان تخفیف نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان تخفیف' />
                </Form.Item>
                <Form.Item
                    label="نوع تخفیف"
                    name="type"
                    rules={[{ required: true, message: 'مقدار نوع تخفیف نمیتواند خالی باشد.' }]}
                >
                    <Select onChange={(value) => { setType(value) }}>
                        <Select.Option value={0}>اختصاص به دسته بندی</Select.Option>
                        <Select.Option value={1}>اختصاص به محصولات</Select.Option>
                        {/* <Select.Option value={2}>اختصاص به مجموع سفارش</Select.Option> */}
                    </Select>
                </Form.Item>
                <Form.Item name="usePercentage" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>استفاده از درصد</Checkbox>
                </Form.Item>
                <Form.Item
                    label="مقدار تخفیف"
                    name="amount"
                    rules={[{ required: true, message: 'مقدار مقدار تخفیف نمیتواند خالی باشد.' }]}
                >
                    <Input type='number' placeholder='مقدار تخفیف' />
                </Form.Item>
                {
                    type === 2 && <Fragment>
                        <Form.Item
                            label="کد تخفیف"
                            name="code"
                            rules={[{ required: true, message: 'کد تخفیف نمیتواند خالی باشد.' }]}
                        >
                            <Input type='number' placeholder='کد تخفیف' />
                        </Form.Item>
                        <Form.Item
                            label="تاریخ شروع"
                            name="startDate"
                        >
                            <DatePicker
                                isGregorian={false} timePicker={false}
                            />
                        </Form.Item>
                        <Form.Item
                            label="تاریخ پایان"
                            name="endDate"
                        >
                            <DatePicker
                                isGregorian={false} timePicker={false}
                            />
                        </Form.Item>
                        <Form.Item
                            label="محدودیت تخفیف"
                            name="limitation"
                            rules={[{ required: true, message: 'مقدار محدودیت تخفیف نمیتواند خالی باشد.' }]}
                        >
                            <Select onChange={(value) => { setLimitation(value) }}>
                                <Select.Option value={0}>نامحدود</Select.Option>
                                <Select.Option value={1}>تنها برای N بار</Select.Option>
                                <Select.Option value={2}>N بار برای هر مشتری</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            limitation !== 0 && <Form.Item
                                label="N بار"
                                name="limitationTimes"
                                rules={[{ required: true, message: 'مقدار N بار نمیتواند خالی باشد.' }]}
                            >
                                <Input type='number' placeholder='N بار' />
                            </Form.Item>
                        }
                        <Form.Item
                                label="حداقل سفارش"
                                name="minimumOrder"
                                rules={[{ required: true, message: 'مقدار حداقل سفارش نمیتواند خالی باشد.' }]}
                            >
                                <Input type='number' placeholder='حداقل سفارش' />
                            </Form.Item>                    
                    </Fragment>
                }
            </Form>

        </Modal>
    </>

}

export default CreateDiscount;