import { Button, Col, Collapse, Form, Modal, Row, Select, Space, Typography, message, Image } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IOrders } from '../../../../../Models';
import moment from 'jalali-moment';
import axios from 'axios';
import { getApiUrl, headerConfig, baseUrl } from '../../../../../Helpers';
const { Text } = Typography;
const { Panel } = Collapse;

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IOrders
}

const CreateCategory = ({ getData, open, setOpen, record }: IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            form.setFieldValue('status', String(record.status));
        }
    }, [open]);

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {
        const payload = form.getFieldsValue();
        console.log(payload);
        const headerRequest = headerConfig();
        axios({
            url: getApiUrl(`/order/${record._id}`),
            method: 'put',
            data: { status: form.getFieldValue('status') },
            headers: headerRequest.headers
        }).then(response => {
            setLoading(false);
            message.success('عملیات با موفقیت انجام شد.');
            hideModal();
            getData();
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        });
    }

    return <>
        <Modal width={800}
            title={'مشاهده جزئیات سفارش'}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            maskClosable={false}
            footer={record.status !== -1 && <div><Button onClick={handleSave} type='primary' loading={loading}>ویرایش وضعیت سفارش</Button></div>}
        >
            <Form name="createCategory"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Row>
                    <Col xs={12}>
                        {
                            record.createDate && <Form.Item>
                                تاریخ سفارش : {moment(record.createDate).locale('fa').format('YYYY/MM/DD HH:mm')}
                            </Form.Item>
                        }
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            کد سفارش : {record.code}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Item>
                            مبلغ سفارش : <Space>
                                <span>{new Intl.NumberFormat().format(record.totalPrice)}</span>
                                <span>تومان</span>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            <Fragment>
                                وضعیت پرداخت :
                                <Text type='success'>{record.status !== -1 && 'پرداخت شده'}</Text>
                                <Text type='danger'>{record.status === -1 && 'پرداخت نشده'}</Text>
                            </Fragment>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Item>
                            شماره تماس  سفارش دهنده : {record.user.phone}
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            نام  سفارش دهنده : {record.user.name}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Form.Item>
                            روش ارسال : {record.shippingMethod}
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item>
                            هزینه ارسال : <Space>
                                <span>{new Intl.NumberFormat().format(record.shippingCost)}</span>
                                <span>تومان</span>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    آدرس سفارش : {record.shippingAddress}
                </Form.Item>
                {
                    record.status !== -1 && <Form.Item
                        label="وضعیت سفارش"
                        name="status"
                        rules={[{ required: true, message: 'مقدار وضعیت سفارش نمیتواند خالی باشد.' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            dropdownMatchSelectWidth={false}
                            placement={'bottomRight'}
                            options={[
                                {
                                    value: '0',
                                    label: 'در حال آماده سازی',
                                },
                                {
                                    value: '1',
                                    label: 'تحویل به پست',
                                },
                                {
                                    value: '2',
                                    label: 'تحویل به پیک ',
                                },
                                {
                                    value: '3',
                                    label: 'تحویل به تیپاکس ',
                                },
                                {
                                    value: '4',
                                    label: 'تحویل مشتری',
                                },
                            ]}
                        />
                    </Form.Item>
                }

                <Collapse className='mt-3 mb-3'>
                    <Panel header="مشاهده جزئیات سفارش" key={1}>
                        <Fragment>
                            <div className='order'>
                                {record.detail.map((product: any) =>
                                    <div className='order-item'>
                                        {
                                            product.title ? <Row>
                                                <Col md={6} xs={24}>
                                                    <div className='text-center'>
                                                        <Image
                                                            width={120}
                                                            src={`${baseUrl}/${product.picture}`}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={18} xs={25}>
                                                    <div className='bold'>{product.title}</div>
                                                    <div className='cart-price'>
                                                        <Space>
                                                            <span>تعداد : </span>
                                                            <span>{product.count}</span>
                                                            <span>عدد</span>
                                                        </Space>
                                                    </div>
                                                    <div className='cart-price'>
                                                        <Space>
                                                            <span>قیمت : </span>
                                                            <span>{new Intl.NumberFormat().format(product.price)}</span>
                                                            <span>تومان</span>
                                                        </Space>
                                                    </div>
                                                    {product.variety && <div className='cart-price'>
                                                        <Space>
                                                            <span>تنوع محصول : </span>
                                                            <span>{product.variety.title}</span>
                                                        </Space>
                                                    </div>}
                                                </Col>
                                            </Row> : <Row>
                                                <Text type="danger">محصول حذف شده است</Text>
                                            </Row>
                                        }

                                    </div>)}
                            </div>
                        </Fragment>
                    </Panel>
                </Collapse>
            </Form>
        </Modal >
    </>

}

export default CreateCategory;