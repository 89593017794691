import { Alert, Avatar, Button, Checkbox, Form, Input, message, Modal, Select, Space, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl, getApiUrl, headerConfig, quiil } from '../../../../../Helpers';
import { IEducation, ICategory } from '../../../../../Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IEducation,
    category: Array<ICategory>
}

const CreateEducation = ({ getData, open, setOpen, record, category }: IProps) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [video, setVideo] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
                form.setFieldValue('category', record.category._id);
            }
            else {
                form.resetFields();
                setFileList([]);
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);
            if (fileList[0]) {
                form.setFieldValue("picture", fileList[0].originFileObj);
            }
            if (video[0]) {
                form.setFieldValue("video", video[0].originFileObj);
            }
            const headerRequest = headerConfig();
            const formData = new FormData();
            const values = form.getFieldsValue();
            formData.append("title", values.title.trim());
            formData.append("description", values.description || ' ');
            formData.append("shortDesc", values.shortDesc || ' ');
            formData.append("picture", values.picture || ' ');
            formData.append("video", values.video || ' ');
            formData.append("active", values.active || false);
            formData.append("category", values.category);
            axios({
                url: getApiUrl(`/education/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: formData,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                setFileList([]);
                setVideo([]);
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileList(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onChangeVideo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("video") !== -1)
            return setVideo(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت mp4 وارد کنید.')
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const readImage = () => {
        navigator.clipboard.readText().then((text) => {
            const description = form.getFieldValue('description') || ''
            form.setFieldsValue({
                description: description + text
            });
        })
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد آموزش جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد آموزش جدید' : `ویرایش آموزش (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createEducation"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان آموزش"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان آموزش نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان آموزش' />
                </Form.Item>

                <Form.Item
                    label="دسته بندی"
                    name="category"
                    rules={[{ required: true, message: 'مقدار دسته بندی نمیتواند خالی باشد.' }]}
                >
                    <Select>
                        {
                            category.map(item => <Select.Option value={item._id}>{item.breadCrumb}</Select.Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label="توضیح کوتاه"
                    name="shortDesc"
                >
                    <TextArea placeholder="توضیح کوتاه" autoSize />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال / غیرفعال</Checkbox>
                </Form.Item>
                <Form.Item
                    label="آپلود عکس"
                    name="picture"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && '+ بارگزاری عکس'}
                    </Upload>
                    {record.picture && <Space>
                        <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                        <Avatar src={`${baseUrl}/${record.picture}`} size={64} />
                    </Space>}
                </Form.Item>
                <Form.Item
                    label="آپلود ویدیو"
                    name="video"
                >
                    <Upload
                        listType="picture-card"
                        fileList={video}
                        onChange={onChangeVideo}
                    >
                        {video.length < 1 && '+ بارگزاری ویدیو'}
                    </Upload>
                    {record.video && <Space>
                        <Alert type="warning" message="اگر ویدیویی آپلود کنید جایگزین ویدیو قبلی میشود." />
                        <a href={`${baseUrl}/${record.video}`} target='_blank'>مشاهده ویدیو</a>
                    </Space>}
                </Form.Item>
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
                <Form.Item label="عکس بیشتر">
                    <Button onClick={readImage}>اضافه کردن عکس از کلیپ بورد</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreateEducation;