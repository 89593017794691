import React, { Fragment, useState } from 'react';
import Layout from '../../Layout';
import { Button, Form, message, Upload, UploadFile, UploadProps, Divider, List, Image as ImageAntd, Space, Tooltip, Popconfirm } from 'antd';
import { RcFile } from 'antd/es/upload';
import { getApiUrl, headerConfig, baseUrl } from '../../../Helpers';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    CopyOutlined, DeleteOutlined, QuestionCircleOutlined
} from '@ant-design/icons';

const FilesPage = () => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<Array<any>>([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState<any>({
        current: 1,
        pageSize: 12,
        total: 0
    });

    React.useEffect(() => {
        getData(pagination.current);
    }, [])

    const getData = (pageNumber: number) => {
        setLoading(true);
        axios.get(getApiUrl(`/files?&pageSize=${pagination.pageSize}&pageNumber=${pageNumber}`)
            , headerConfig()).then((response: any) => {
                setFiles(response.data.files);
                setPagination({
                    pageSize: pagination.pageSize,
                    current: pageNumber,
                    total: response.data.totalCount
                })
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const handleSave = () => {
        if (fileList.length === 0)
            return message.error('فایلی انتخاب نکرده اید');

        const headerRequest = headerConfig();
        const formData = new FormData();
        formData.append("photo", fileList[0].originFileObj || ' ');
        setLoading(true);

        axios({
            url: getApiUrl(`/files/`),
            method: 'post',
            data: formData,
            headers: headerRequest.headers
        }).then(response => {
            setLoading(false);
            message.success('عملیات با موفقیت انجام شد.');
            getData(pagination.current);
            setFileList([]);
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList.length > 0) {
            if (newFileList[0].type?.indexOf("image") !== -1)
                return setFileList(newFileList);
            message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
        }
        setFileList(newFileList);
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const copyLink = (url: string) => {      
        var textField = document.createElement('textarea')
        textField.innerHTML = `<img src="${url}" class="img-responsive" alt="" />`
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
        message.success('لینک عکس با موفقیت کپی شد.');
    }

    const deleteFile = (id: string) => {
        axios.delete(getApiUrl(`/files/${id}`), headerConfig()).then(response => {
            message.success('عملیات با موفقیت انجام شد.');
            let current: number = pagination.current || 1;
            if (pagination.current !== 1 && files.length === 1) {
                current = current - 1
            }
            getData(current);
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
        })
    }


    return <Layout>
        <Divider orientation="left" plain>
            آپلود فایل
        </Divider>
        <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            onFinish={handleSave}>
            <Form.Item>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                >
                    {fileList.length < 1 && '+ بارگزاری عکس'}
                </Upload>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    ذخیره
                </Button>
            </Form.Item>
        </Form>
        <Divider orientation="left" plain>
            فایل های آپلود شده
        </Divider>

        <List
            itemLayout="vertical"
            grid={{ gutter: 16, column: 6 }}
            size="large"
            pagination={{
                onChange: (page) => {
                    getData(page);
                },
                pageSize: pagination.pageSize,
                current: pagination.current,
                total: pagination.total
            }}
            dataSource={files}
            renderItem={(item) => (
                <List.Item
                    key={item._id}
                    extra={
                        <Fragment>
                            <ImageAntd
                                width={150}
                                height={120}
                                src={`${baseUrl}/${item.picture}`}
                            />
                            <div style={{ marginTop: 10 }}>
                                <Space>
                                    <Tooltip title={'کپی'}>
                                        <CopyOutlined rev={'span'} onClick={() => copyLink(`${baseUrl}/${item.picture}`)} />
                                    </Tooltip>
                                    <Tooltip title={'حذف'}>
                                        <Popconfirm
                                            title="حذف فایل"
                                            description="با حذف فایل ، فایل مورد نظر به صورت کامل حذف میشود . آیا مطمئن هستید ؟؟"
                                            icon={<QuestionCircleOutlined rev={'span'} style={{ color: 'red' }}
                                            />}
                                            onConfirm={() => deleteFile(item._id)}
                                            okText="بله"
                                            cancelText="خیر"
                                        >
                                            <DeleteOutlined rev={'span'} />
                                        </Popconfirm>
                                    </Tooltip>
                                </Space>
                            </div>
                        </Fragment>
                    }
                >
                </List.Item>
            )}
        />
    </Layout>
}

export default FilesPage;