import { message, Space, Table, Input, TablePaginationConfig, Typography, Alert } from 'antd';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { IActivities } from '../../../Models';
import { Dispatch } from "redux";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { changeCountNotification } from "../../../store/actionCreators";
import { Column, ColumnConfig } from '@ant-design/plots';
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const DashboardPage = () => {

    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const [search, setSearch] = useState<string>('');
    const [activities, setActivities] = useState<Array<IActivities>>([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    const countNotification: number = useSelector(
        (state: any) => state.countNotification,
        shallowEqual
    );

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);


    const columns: ColumnsType<IActivities> = [
        {
            title: 'خوانده شده؟',
            dataIndex: 'read',
            key: 'read',
            render: (_, record) => (
                record.read ? <Text type='success'><CheckOutlined rev={'span'} /></Text> :
                    <Text type="danger"><CloseOutlined rev={'span'} /></Text>
            ),
            width: 40
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.title}
                />
            ),
            width: 100
        },
        {
            title: 'متن پیغام',
            dataIndex: 'description',
            key: 'description',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.description}
                />
            ),
            width: 300,
        }
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/activities?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setActivities(response.data.activities);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
        axios.get(getApiUrl(`/visit`)
            , headerConfig()).then((response: any) => {
                console.log(response);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    const readMessage = (record: IActivities) => {
        if (record.read) return;
        axios.get(getApiUrl(`/activities/readMessage/${record._id}`)
            , headerConfig()).then((response: any) => {
                let find = activities.find(item => item._id === record._id);
                if (find)
                    find.read = true;
                setActivities(JSON.parse(JSON.stringify(activities)));
                dispatch(changeCountNotification(countNotification - 1));
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
            })
    }

    return <Layout>
        <Alert type='warning' message='برای مشاهده جزئیات بیشتر پیام ها وارد صفحه "انتقادات و پیشنهادات " شوید.' style={{ marginBottom: 10 }} />
        <Space className='panel-search'>
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={activities}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => readMessage(record)
                };
            }} />
    </Layout>
}

export default DashboardPage;