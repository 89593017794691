import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { useState } from 'react';
import Layout from '../../Layout';
import { getApiUrl, headerConfig } from '../../../Helpers';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Dispatch } from "redux";
import { useDispatch } from 'react-redux';
import { init } from "../../../store/actionCreators";


const LoginPage = () => {

    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();

    const onFinish = (values: any) => {
        setLoading(true);
        axios.post(getApiUrl('/users/login'), values, headerConfig()).then(response => {
            setLoading(false);
            const headers = response.headers['x-auth-token'];
            localStorage.setItem('token', headers);
            axios.get(getApiUrl(`/users/profile`)
                , headerConfig()).then((response: any) => {
                    if (response.data) {
                        dispatch(init(response.data));
                        navigate('/');
                    }
                });
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if (token) {
        return <Navigate to='/' />
    }

    return <Layout mode="login">
        <Row style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Col xs={22} sm={22} md={16} lg={8} xl={8}>
                <Card title="ورود به پنل مدیریت سایت">
                    <Form
                        name="login"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >                        
                        <Form.Item                         
                            name="phone"
                            rules={[
                                { required: true, message: 'لطفا شماره همراه را وارد کنید' },
                                { pattern: new RegExp("^09\\d{9}$"), message: 'ساختار شماره همراه صحیح نمیباشد.' },
                            ]}
                        >
                            <Input prefix={<UserOutlined rev={'span'} className="site-form-item-icon" />} size="large" type='number' placeholder="شماره همراه" />
                        </Form.Item>

                        <Form.Item                        
                            name="password"
                            rules={[{ required: true, message: 'لطفا رمز عبور را وارد کنید' }]}
                        >
                            <Input.Password prefix={<LockOutlined  rev={'span'}className="site-form-item-icon" />} size="large" placeholder="رمز عبور" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                                ورود
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>

    </Layout>
}

export default LoginPage;