import { Avatar, Button, Form, Input, message, Modal, Select, Space, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import { useEffect, useState } from 'react';
import moment from 'jalali-moment';
import DatePicker from 'react-datepicker2';
import { useNavigate } from 'react-router-dom';
import { baseUrl, getApiUrl, headerConfig } from '../../../../../Helpers';
import { IUser } from '../../../../../Models';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IUser
}

const CreateUser = ({ getData, open, setOpen, record }: IProps) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue({
                    name: record.name,
                    phone: record.phone,
                    description: record.description,
                    role: record.role,
                    birthDay: moment(record.birthDay)
                });
            }
            else {
                form.resetFields();
                setFileList([]);
                form.setFieldValue('role', 'user');
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);
            if (fileList[0]) {
                form.setFieldValue("picture", fileList[0].originFileObj);
            }
            const headerRequest = headerConfig();
            const formData = new FormData();
            const values = form.getFieldsValue();
            formData.append("name", values.name.trim());
            formData.append("description", values.description || ' ');
            formData.append("picture", values.picture || ' ');
            formData.append("phone", values.phone);
            formData.append("birthDay", values.birthDay);
            formData.append("password", values.password);
            formData.append("role", values.role);
            axios({
                url: getApiUrl(`/users/register/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: formData,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                setFileList([]);
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileList(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد کاربر جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد کاربر جدید' : `مشاهده کاربر (${record.name})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            maskClosable={false}
            footer={!record._id && <div><Button onClick={handleSave} type='primary' loading={loading}>ثبت</Button></div>}
        >
            <Form name="CreateUser"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="نام و نام خانوادگی"
                    name="name"
                    rules={[{ required: true, message: 'مقدار نام و نام خانوادگی نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='نام و نام خانوادگی' disabled={!!record._id} />
                </Form.Item>
                <Form.Item
                    label="شماره همراه"
                    name="phone"
                    rules={[
                        { required: true, message: 'شماره همراه نمی تواند خالی باشد.' },
                        { pattern: new RegExp("^09\\d{9}$"), message: 'ساختار شماره تلفن صحیح نمیباشد.' },
                    ]}
                >
                    <Input type='number' placeholder='شماره همراه' />
                </Form.Item>
                {
                    !record._id && <Form.Item
                        label="رمز عبور"
                        name="password"
                        rules={[{ required: true, message: 'مقدار رمز عبور نمیتواند خالی باشد.' }]}
                    >
                        <Input placeholder='رمز عبور' />
                    </Form.Item>
                }
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <TextArea placeholder="توضیحات" autoSize disabled={!!record._id} />
                </Form.Item>
                <Form.Item
                    label="تاریخ تولد"
                    name="birthDay"
                >
                    <DatePicker
                        isGregorian={false} timePicker={false}
                    />
                </Form.Item>
                <Form.Item
                    label="دسترسی"
                    name="role"
                >
                    <Select
                        disabled={!!record._id}
                        placeholder="دسترسی"
                        options={[
                            {
                                value: 'user',
                                label: 'کاربر عادی',
                            },
                            {
                                value: 'admin',
                                label: 'کاربر ادمین',
                            }
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="آپلود عکس"
                    name="picture"
                >
                    {
                        !record._id && <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                        >
                            {fileList.length < 1 && '+ بارگزاری عکس'}
                        </Upload>
                    }
                    {record.picture && <Space>
                        <Avatar src={`${baseUrl}/${record.picture}`} size={64} />
                    </Space>}
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreateUser;