import * as actionTypes from "./actionTypes";
import { IUser } from '../Models';

export function init(user: IUser) {
    const action = {
        type: actionTypes.Init,
        user,
    }

    return simulateHttpRequest(action);
}

export function changeCountNotification(countNotification:number) {
    const action = {
        type: actionTypes.ChangeCountNotification,
        countNotification,
    }

    return simulateHttpRequest(action);
}

export function simulateHttpRequest(action: any) {
    return (dispatch: any) => {
        dispatch(action);
    }
}