import React from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: any) => {    
    const token = localStorage.getItem('token');

    if (!token) {
        return <Navigate to='/login' />
    }

    return children;
};

export default ProtectedRoute;