import { Alert, Avatar, Button, Form, Input, message, Space, Spin, Upload, UploadFile, UploadProps } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../Models'
import { baseUrl, getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import { RcFile } from 'antd/es/upload';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { init } from "../../../store/actionCreators";

const EditProfilePage = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch: Dispatch<any> = useDispatch();
    const user: IUser = useSelector(
        (state: any) => state.user,
        shallowEqual
    );

    useEffect(() => {
        form.setFieldsValue(user);
    }, [user]);

    const getData = () => {
        setLoading(true);
        axios.get(getApiUrl(`/users/profile`)
            , headerConfig()).then((response: any) => {
                if (response.data) {
                    dispatch(init(response.data));
                }
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onSave = (values: any) => {
        const headerRequest = headerConfig();
        setLoadingButton(true);

        if (fileList[0]) {
            form.setFieldValue("picture", fileList[0].originFileObj);
        }
        const formData = new FormData();
        formData.append("id", user?._id || '');
        formData.append("name", values.name.trim());
        formData.append("phone", values.phone);
        formData.append("picture", form.getFieldValue('picture'));

        axios({
            url: getApiUrl(`/users/profile`),
            method: 'post',
            data: formData,
            headers: headerRequest.headers
        }).then(response => {
            setLoadingButton(false);
            message.success('عملیات با موفقیت انجام شد.');
            setFileList([]);
            getData();
        }).catch(error => {
             if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoadingButton(false);
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileList(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    return <Layout>
        <Spin spinning={loading}>
            <Form
                name="editProfile"
                form={form}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                autoComplete="off"
                onFinish={onSave}>
                <Form.Item
                    label="نام و نام خانوادگی"
                    name="name"
                    rules={[{ required: true, message: 'مقدار نام و نام خانوادگی نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='نام و نام خانوادگی' />
                </Form.Item>
                <Form.Item
                    label="شماره همراه"
                    name="phone"
                    rules={[{ required: true, message: 'مقدار شماره همراه نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='شماره همراه' />
                </Form.Item>
                <Form.Item
                    label="آپلود عکس"
                    name="picture"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && '+ بارگزاری عکس'}
                    </Upload>
                    {user?.picture && <Space>
                        <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                        <Avatar src={`${baseUrl}/${user?.picture}`} size={64} />
                    </Space>}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                    <Button type="primary" htmlType="submit" loading={loadingButton}>
                        ذخیره
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </Layout>
}

export default EditProfilePage;