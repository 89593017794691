import { Alert, Avatar, Button, Checkbox, Col, Collapse, Form, Input, message, Modal, Row, Select, Space, Upload, UploadFile, UploadProps, ColorPicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl, getApiUrl, headerConfig, quiil } from '../../../../../Helpers';
import { IProduct, ICategory, IDiscount } from '../../../../../Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeleteOutlined } from '@ant-design/icons';
import { Extension } from '../../../../../Components';
const { Panel } = Collapse;


interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IProduct,
    category: Array<ICategory>,
    discount: Array<IDiscount>
}

const CreateProduct = ({ getData, open, setOpen, record, category, discount }: IProps) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [img, setImg] = useState<Array<any>>([]);
    const [variety, setVariety] = useState<Array<any>>([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
                form.setFieldValue('category', record.category._id);
                form.setFieldValue('discount', record.discount?._id);
                if (record.images) setImg(JSON.parse(record.images));
                else setImg([]);
                if (record.variety) setVariety(JSON.parse(record.variety));
                else setVariety([]);
            }
            else {
                form.resetFields();
                setFileList([]);
                setImg([]);
                setVariety([]);
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {

            for (var i = 0; i < variety.length; i++) {
                if (!variety[i].title || !variety[i].price || !variety[i].color) return message.error('لطفا مقادیر تنوع محصول را پر کنید!');
            }
            setLoading(true);
            if (fileList[0]) {
                form.setFieldValue("picture", fileList[0].originFileObj);
            }
            const headerRequest = headerConfig();
            const formData = new FormData();
            const values = form.getFieldsValue();
            formData.append("title", values.title.trim());
            formData.append("description", values.description || ' ');
            formData.append("specifications", values.specifications || ' ');
            formData.append("features", values.features || ' ');
            formData.append("shortDesc", values.shortDesc || ' ');
            formData.append("picture", values.picture || ' ');
            formData.append("showInHomePage", values.showInHomePage || false);
            formData.append("callForPrice", values.callForPrice || false);
            formData.append("festival", values.festival || false);
            formData.append("count", values.count || 0);
            formData.append("price", values.price || 0);
            formData.append("OrderLimit", values.OrderLimit || 0);
            formData.append("category", values.category);
            formData.append("images", JSON.stringify(img));
            formData.append("variety", JSON.stringify(variety));
            values.discount && formData.append("discount", values.discount);
            axios({
                url: getApiUrl(`/product/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: formData,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                setFileList([]);
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileList(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const readImage = () => {
        navigator.clipboard.readText().then((text) => {
            setImg([...img, text]);
        })
    }

    const deleteImg = (url: any) => {
        setImg(img.filter(item => item !== url));
    }

    const onChangeVariety = (e: any, filed: string, index: number) => {
        var varietyCopy = [...variety];
        switch (filed) {
            case 'title': varietyCopy[index].title = e.target.value; break;
            case 'price': varietyCopy[index].price = e.target.value; break;
            case 'color': varietyCopy[index].color = e; break;
            case 'active': varietyCopy[index].active = e.target.checked; break;
        }
        setVariety(varietyCopy);
    }

    const addVariety = () => {
        for (var i = 0; i < variety.length; i++) {
            if (!variety[i].title) return;
        }
        setVariety([{
            title: null,
            price: null,
            color: '#1677ff',
            active: false
        }, ...variety])
    }

    const removeVariety = (index: number) => {
        const varietyCopy = [...variety];
        varietyCopy.splice(index, 1);
        setVariety(varietyCopy);
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد محصول جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد محصول جدید' : `ویرایش محصول (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createProduct"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان محصول"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان محصول نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان محصول' />
                </Form.Item>

                <Form.Item
                    label="دسته بندی"
                    name="category"
                    rules={[{ required: true, message: 'مقدار دسته بندی نمیتواند خالی باشد.' }]}
                >
                    <Select>
                        {
                            category.map(item => <Select.Option value={item._id}>{item.breadCrumb}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="تعداد محصول"
                    name="count"
                    rules={[{ required: true, message: 'مقدار تعداد محصول نمیتواند خالی باشد.' }]}
                >
                    <Input type='number' placeholder='تعداد محصول' />
                </Form.Item>
                <Form.Item
                    label="محدودیت هر سفارش"
                    name="OrderLimit"
                    rules={[{ required: false }]}
                >
                    <Input type='number' placeholder='تعداد محصول' />
                </Form.Item>
                <Form.Item
                    label="قیمت محصول"
                    name="price"
                    rules={[{ required: true, message: 'مقدار قیمت محصول نمیتواند خالی باشد.' }]}
                >
                    <Input type='number' placeholder='قیمت محصول' />
                </Form.Item>
                <Form.Item
                    label="تخفیف"
                    name="discount"
                >
                    <Select allowClear>
                        {
                            discount.map((item: any) => <Select.Option value={item._id}>{item.title}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="توضیح کوتاه"
                    name="shortDesc"
                >
                    <TextArea placeholder="توضیح کوتاه" autoSize />
                </Form.Item>
                <Form.Item name="showInHomePage" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>نمایش به عنوان پرفروش ترین محصولات</Checkbox>
                </Form.Item>
                <Form.Item name="festival" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>نمایش در جشنواره</Checkbox>
                </Form.Item>
                <Form.Item name="callForPrice" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>برای قیمت تماس بگیرید</Checkbox>
                </Form.Item>
                <Form.Item
                    label="آپلود عکس"
                    name="picture"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && '+ بارگزاری عکس'}
                    </Upload>
                    {record.picture && <Space>
                        <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                        <Avatar src={`${baseUrl}/${record.picture}`} size={64} />
                    </Space>}
                </Form.Item>
                <Form.Item label="عکس های بیشتر">
                    <Collapse>
                        <Panel header="افزودن عکس های بیشتر محصول" key={1}>
                            <Fragment>
                                <Button onClick={readImage}>paste</Button>
                                <hr />
                                {
                                    img.map((item: any) => <Space style={{ marginRight: 10 }}>
                                        <div style={{ width: 100 }} dangerouslySetInnerHTML={{ __html: item }} />
                                        <DeleteOutlined rev={'span'} style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteImg(item)} />
                                    </Space>)
                                }
                            </Fragment>
                        </Panel>
                    </Collapse>
                </Form.Item>
                <Form.Item label="تنوع">
                    <Collapse>
                        <Panel header="افزودن تنوع محصول" key={1}>
                            <Fragment>
                                <Button onClick={addVariety}>افزودن جدید</Button>
                                <hr />
                                {variety.map((item, index) => <Row>
                                    <Col span={4}>
                                        <Form.Item
                                            label="فعال (رنگ)"
                                            rules={[{ required: true }]}
                                            
                                        >
                                            <Checkbox checked={item.active}
                                            onChange={(e) => onChangeVariety(e, 'active', index)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="رنگ"
                                            rules={[{ required: true }]}
                                        >
                                            <ColorPicker
                                                value={item.color}
                                                onChangeComplete={(color) => {
                                                    onChangeVariety(color.toHexString(), 'color', index)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="عنوان"
                                            rules={[{ required: true }]}
                                        >
                                            <Input value={item.title} onChange={(e) => onChangeVariety(e, 'title', index)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            label="مقدار"
                                            rules={[{ required: true }]}
                                        >
                                            <Input type="number" value={item.price} onChange={(e) => onChangeVariety(e, 'price', index)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                        >
                                            <Button type="primary" danger onClick={(e) => removeVariety(index)}>حذف</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>)}
                            </Fragment>
                        </Panel>
                    </Collapse>
                </Form.Item>
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
                <Form.Item
                    label="ویژگی ها"
                    name="features"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
                <Form.Item
                    label="مشخصات"
                    name="specifications"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default CreateProduct;