import axios from 'axios';
import Layout from '../../Layout';
import { Column, ColumnConfig } from '@ant-design/plots';
import { getApiUrl, headerConfig } from '../../../Helpers';
import { useEffect, useState } from 'react';
import { Alert, Collapse, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Panel } = Collapse;

const DashboardPage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        axios.get(getApiUrl(`/visit`)
            , headerConfig()).then((response: any) => {
                setData(response.data);
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
    }

    const config = {
        data,
        xField: 'day',
        yField: 'count',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        label: {

        },
        meta: {
            type: {
                alias: 'روز',
            },
            sales: {
                alias: 'تعداد بازدید',
            },
        },
    };

    return <Layout>
        {!loading && <Column {...config} />}
        <Collapse style={{ marginTop: 20 }}>
            <Panel header="اطلاعات سایز عکس ها" key={1}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert message="ابعاد لوگوی سایت 180 در 75 پیکسل باشد." type="error" />
                    <Alert message="ابعاد آیکون سایت 32 در 32 پیکسل باشد." type="success" />
                    <Alert message="برای لود سریع سایت خود لطفا حجم عکس  بیشتر از 200 کیلو بایت آپلود نکنید . در ضمن هیچ محدودیتی در حجم فایل آپلودی قرار داده نشده است ." type="warning" />
                    <Alert message="ابعاد عکس های اسلایدر 1980 در 450 پیکسل باشد." type="info" />
                    <Alert message="ابعاد عکس های محصول 550 در 550 پیکسل باشد." type="success" />
                    <Alert message="ابعاد عکس های وبلاگ 750 در 450 پیکسل باشد." type="error" />
                    <Alert message="ابعاد عکس بنری بالای سایت 1980 در 120 پیکسل باشد." type="warning" />
                    <Alert message="ابعاد عکس بنری پایین اسلایدر 450 در 300 پیکسل باشد." type="info" />
                    <Alert message="ابعاد عکس بنری بالای آخرین مقالات 1500 در 350 پیکسل باشد." type="success" />
                    <Alert message="ابعاد عکس بنری پایین جشنواره مقالات 750 در 250 پیکسل باشد." type="error" />
                </Space>
            </Panel>
        </Collapse>
    </Layout>
}

export default DashboardPage;