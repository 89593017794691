import { Avatar, message, Popconfirm, Space, Table, Typography, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import  moment from 'jalali-moment';
import { getApiUrl, headerConfig, baseUrl } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { CreateUser } from './Components';
import { IUser } from '../../../Models';
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const CategoryPage = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [users, setUsers] = useState<Array<IUser>>([]);
    const empty: IUser = { name: '', description: '', _id: '', picture: '', active: false, role: 'user', phone: '', isSystem: false, birthDay: new Date() }
    const [record, setRecord] = useState<IUser>(empty);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);

    useEffect(() => {
        if (!open) {
            setRecord(empty);
        }
    }, [open])

    const deleteUser = (id: string) => {
        setLoading(true);
        axios.delete(getApiUrl(`/users/${id}`), headerConfig()).then(response => {
            message.success('عملیات با موفقیت انجام شد.');
            let current: number = tableParams.pagination.current || 1;
            if (tableParams.pagination.current !== 1 && users.length === 1) {
                current = current - 1
            }
            getData({ ...tableParams.pagination, current });
        }).catch(error => {
             if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        })
    }

    const viewUser = (record: IUser) => {
        setRecord(record);
        setOpen(true);
    }

    const columns: ColumnsType<IUser> = [
        {
            title: 'عکس',
            dataIndex: 'picture',
            key: 'picture',
            render: (_, record) => (
                <Avatar src={`${baseUrl}/${record.picture}`} size={40} />
            ),
            width: 50,
            responsive: ["md", "lg"],
        },
        {
            title: 'نام و نام خانوادگی',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.name}
                />
            ),
            width: 150
        },
        {
            title: 'تاریخ تولد',
            dataIndex: 'birthDay',
            key: 'birthDay',
            render: (_, record) => (
                <div>{moment(record.birthDay).locale('fa').format('YYYY/MM/DD')}</div>
            ),
            width: 100
        },
        {
            title: 'شماره تلفن',
            dataIndex: 'phone',
            key: 'phone',
            responsive: ["md", "lg"],
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.phone}
                />
            ),
            width: 150
        },
        {
            title: 'دسترسی',
            dataIndex: 'role',
            key: 'role',
            responsive: ["md", "lg"],
            width: 100
        },
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => viewUser(record)}>مشاهده</a>
                    {
                        !record.isSystem && <Popconfirm
                            placement="top"
                            title={'حذف دسته بندی'}
                            description={'آیا مطمئن هستید؟؟'}
                            onConfirm={() => deleteUser(record._id)}
                            okText="بله"
                            cancelText="خیر"
                        >
                            <a><Text type="danger">حذف</Text></a>
                        </Popconfirm>
                    }
                </Space>
            ),
            width: 150
        },
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/users/?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setUsers(response.data.users);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <CreateUser open={open} setOpen={setOpen} record={record} getData={() => getData(tableParams.pagination)} />
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={users}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default CategoryPage;