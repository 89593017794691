import axios from "axios";
import React from "react";
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ErrorPage, SliderPage, PostPage, LoginPage, OfferPage
  , UsersPage, SettingPage, EditProfilePage, CategoryPage, ProtectedRoute, DashboradPage,
  FaqPage, ChangePassword , ProjectPage , PlanPage , ProductPage , MessagePage , ManagementPage , FilesPage 
  , OrderPage , BannerPage , DiscountPage , EducationPage
} from './Components';
import { getApiUrl, headerConfig } from "./Helpers";
import { Dispatch } from "redux";
import { init, changeCountNotification } from "./store/actionCreators";

function App() {

  const dispatch: Dispatch<any> = useDispatch();

  React.useEffect(() => {
    axios.get(getApiUrl(`/users/profile`)
      , headerConfig()).then((response: any) => {
        dispatch(init(response.data));
      });
    axios.get(getApiUrl(`/activities/countNotification`)
      , headerConfig()).then((response: any) => {
        dispatch(changeCountNotification(response.data.totalCount));
      });
  }, []);

  const router = createHashRouter([
    {
      path: "/",
      element: <ProtectedRoute><DashboradPage /></ProtectedRoute>,
      errorElement: <ErrorPage />
    },   
    {
      path: "/message",
      element: <ProtectedRoute><MessagePage /></ProtectedRoute>,
      errorElement: <ErrorPage />
    },   
    {
      path: "/category",
      element: <ProtectedRoute><CategoryPage /></ProtectedRoute>
    },
    {
      path: "/users",
      element: <ProtectedRoute><UsersPage /></ProtectedRoute>
    },
    {
      path: "/post",
      element: <ProtectedRoute><PostPage /></ProtectedRoute>
    },
    {
      path: "/editProfile",
      element: <ProtectedRoute><EditProfilePage /></ProtectedRoute>
    },
    {
      path: "/setting",
      element: <ProtectedRoute><SettingPage /></ProtectedRoute>
    },
    {
      path: "/slider",
      element: <ProtectedRoute><SliderPage /></ProtectedRoute>
    },
    {
      path: "/login",
      element: <LoginPage />
    },
    {
      path: "/faq",
      element: <ProtectedRoute><FaqPage /></ProtectedRoute>
    },
    {
      path: "/offer",
      element: <ProtectedRoute><OfferPage /></ProtectedRoute>
    },
    {
      path: "/changePassword",
      element: <ProtectedRoute><ChangePassword /></ProtectedRoute>
    },
    {
      path: "/project",
      element: <ProtectedRoute><ProjectPage /></ProtectedRoute>
    },
    {
      path: "/plan",
      element: <ProtectedRoute><PlanPage /></ProtectedRoute>
    },
    {
      path: "/products",
      element: <ProtectedRoute><ProductPage /></ProtectedRoute>
    },
    {
      path: "/pages",
      element: <ProtectedRoute><ManagementPage /></ProtectedRoute>
    },
    {
      path: "/files",
      element: <ProtectedRoute><FilesPage /></ProtectedRoute>
    },
    {
      path: "/order",
      element: <ProtectedRoute><OrderPage /></ProtectedRoute>
    },
    {
      path: "/banner",
      element: <ProtectedRoute><BannerPage /></ProtectedRoute>
    },
    {
      path: "/discount",
      element: <ProtectedRoute><DiscountPage /></ProtectedRoute>
    },
    {
      path: "/education",
      element: <ProtectedRoute><EducationPage /></ProtectedRoute>
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;
