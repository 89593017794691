import { Alert, Avatar, Button, Checkbox, Form, Input, message, Modal, Select, Space, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl, getApiUrl, headerConfig, quiil } from '../../../../../Helpers';
import { IPlan } from '../../../../../Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IPlan,
}

const CreatePlan = ({ getData, open, setOpen, record }: IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            console.log(record);
            if (record._id !== '') {
                form.setFieldsValue(record);
            }
            else {
                form.resetFields();
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);
            const headerRequest = headerConfig();       
            const values = form.getFieldsValue();           
            axios({
                url: getApiUrl(`/plan/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: values,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }


    return <>
        <Button onClick={showModal}>
            + ایجاد پلن جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد پلن جدید' : `ویرایش پلن (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createPlan"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان پلن"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان پلن نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان پلن' />
                </Form.Item>

                <Form.Item
                    label="قیمت پلن"
                    name="price"
                    rules={[{ required: true, message: 'مقدار قیمت پلن نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='قیمت پلن' />
                </Form.Item>

                <Form.Item name="active" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال / غیرفعال</Checkbox>
                </Form.Item>
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreatePlan;