import { Button, Checkbox, Form, Input, message, Modal } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, headerConfig, quiil } from '../../../../../Helpers';
import { IQuestion, IUser } from '../../../../../Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector, shallowEqual } from "react-redux";

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IQuestion
}

const CreateFaq = ({ getData, open, setOpen, record }: IProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const user: IUser = useSelector(
        (state: any) => state.user,
        shallowEqual
    );

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
            }
            else {
                form.resetFields();
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);

            const headerRequest = headerConfig();
            const values = form.getFieldsValue();

            axios({
                url: getApiUrl(`/question/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: { ...values, creator: user.name, phone: user.phone },
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد پرسش و پاسخ جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد پرسش و پاسخ جدید' : `ویرایش پرسش و پاسخ (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createFaq"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان پرسش و پاسخ"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان پرسش و پاسخ نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان پرسش و پاسخ' />
                </Form.Item>
                <Form.Item name="showInSite" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>نمایش در سایت</Checkbox>
                </Form.Item>
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreateFaq;