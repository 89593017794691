import React, { useEffect, useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    HomeOutlined,
    TableOutlined,
    SettingOutlined,
    BarChartOutlined,
    FileImageOutlined,
    ScheduleOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    DownOutlined,
    ProjectOutlined,
    MoneyCollectOutlined,
    ShoppingCartOutlined,
    GroupOutlined,
    PictureOutlined
} from '@ant-design/icons';
import { Avatar, Badge, Col, Layout, Menu, Row, Space, theme, Dropdown } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { useSelector, shallowEqual } from "react-redux";
import { baseUrl } from '../../Helpers';
import { IUser } from '../../Models';
const { Header, Sider, Content } = Layout;


export default ({ children, mode }: any) => {

    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        if (windowWidth < 676) {
            setCollapsed(true);
        }
    }, [windowWidth]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();

    const logOut = () => {
        localStorage.removeItem('token');
        navigate('/login');
    }

    const items: MenuProps['items'] = [
        {
            label: <Link to={'/editProfile'}>تغییر پروفایل</Link>,
            key: '0',
        },
        {
            label: <Link to={'/changePassword'}>تغییر رمز عبور</Link>,
            key: '1',
        },
        {
            label: <a onClick={logOut}>خروج</a>,
            key: '2',
        }
    ];

    const countNotification: number = useSelector(
        (state: any) => state.countNotification,
        shallowEqual
    );

    const user: IUser = useSelector(
        (state: any) => state.user,
        shallowEqual
    );

    if (mode)
        return children;
    return (
        <Layout hasSider>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', position: 'fixed', right: 0, top: 0, overflowY: 'auto' }}>
                <div className="logo">پنل مدیریت سایت</div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={[
                        {
                            key: '/',
                            icon: <HomeOutlined rev={'span'} />,
                            label: <Link to={'/'}>داشبورد</Link>,
                        },
                        {
                            key: '/category',
                            icon: <TableOutlined rev={'span'} />,
                            label: <Link to={'/category'}>مدیریت دسته بندی</Link>,
                        },
                        {
                            key: '/users',
                            icon: <UserOutlined rev={'span'} />,
                            label: <Link to={'/users'}>مدیریت کاربران</Link>,
                        },
                        {
                            key: '/products',
                            icon: <ShoppingCartOutlined rev={'span'} />,
                            label: <Link to={'/products'}>مدیریت محصولات</Link>,
                        },
                        {
                            key: '/discount',
                            icon: <ShoppingCartOutlined rev={'span'} />,
                            label: <Link to={'/discount'}>مدیریت تخفیف ها</Link>,
                        },
                        {
                            key: '/order',
                            icon: <ShoppingCartOutlined rev={'span'} />,
                            label: <Link to={'/order'}>مدیریت سفارش ها</Link>,
                        },
                        {
                            key: '/slider',
                            icon: <FileImageOutlined rev={'span'} />,
                            label: <Link to={'/slider'}>مدیریت اسلایدر</Link>,
                        },
                        {
                            key: '/post',
                            icon: <ScheduleOutlined rev={'span'} />,
                            label: <Link to={'/post'}>مدیریت بلاگ</Link>,
                        },
                        {
                            key: '/education',
                            icon: <ScheduleOutlined rev={'span'} />,
                            label: <Link to={'/education'}>مدیریت آموزش ها</Link>,
                        },
                        {
                            key: '/offer',
                            icon: <EditOutlined rev={'span'} />,
                            label: <Link to={'/offer'}>انتقادات و پیشنهادات</Link>,
                        },
                        {
                            key: '/faq',
                            icon: <QuestionCircleOutlined rev={'span'} />,
                            label: <Link to={'/faq'}>پرسش و پاسخ</Link>,
                        },
                        {
                            key: '/project',
                            icon: <ProjectOutlined rev={'span'} />,
                            label: <Link to={'/project'}>نمونه کارها</Link>,
                        },
                        {
                            key: '/plan',
                            icon: <MoneyCollectOutlined rev={'span'} />,
                            label: <Link to={'/plan'}>مدیریت پلن ها</Link>,
                        },
                        {
                            key: '/pages',
                            icon: <GroupOutlined rev={'span'} />,
                            label: <Link to={'/pages'}>مدیریت صفحات</Link>,
                        },
                        {
                            key: '/files',
                            icon: <PictureOutlined rev={'span'} />,
                            label: <Link to={'/files'}>مدیریت فایل ها</Link>,
                        },
                        {
                            key: '/banner',
                            icon: <FileImageOutlined rev={'span'} />,
                            label: <Link to={'/banner'}>مدیریت بنرها</Link>,
                        },
                        {
                            key: '/setting',
                            icon: <SettingOutlined rev={'span'} />,
                            label: <Link to={'/setting'}>تنظیمات سایت</Link>,
                        }
                    ]}
                >
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{ marginRight: collapsed ? 80 : 200, transition: 'all 0.2s' }}>
                <Header style={{
                    padding: '0px 0px 0px 20px', background: colorBgContainer, position: 'fixed', width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 200px)',
                    zIndex: 10, transition: 'all 0.2s'
                }}>
                    <Row>
                        <Col span={4}>
                            {collapsed ? <MenuUnfoldOutlined className='trigger' onClick={() => setCollapsed(!collapsed)} rev={'span'} />
                                : <MenuFoldOutlined className='trigger' onClick={() => setCollapsed(!collapsed)} rev={'span'} />}
                        </Col>
                        <Col span={20}>
                            <div style={{ textAlign: 'left' }}>
                                <Space>
                                    <Dropdown menu={{ items }} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                {user?.name} عزیز
                                                <DownOutlined rev={'span'} />
                                            </Space>
                                        </a>
                                    </Dropdown>
                                    <Link to={'/message'}>
                                        <Badge count={countNotification}>
                                            {user?.picture && <Avatar shape="square" src={`${baseUrl}/${user?.picture}`} />}
                                            {!user?.picture && <Avatar shape="square" icon={<UserOutlined rev={'span'} />} />}
                                        </Badge>
                                    </Link>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content
                    style={{
                        margin: '95px 30px 0px',
                        padding: 24,
                        minHeight: 'calc(100vh - 125px)',
                        background: colorBgContainer,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}