import { Alert, Avatar, Button, Collapse, Form, Input, message, Space, Spin, Upload, UploadFile, UploadProps } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISetting } from '../../../Models'
import { baseUrl, getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import { RcFile } from 'antd/es/upload';
const { Panel } = Collapse;

const SettingPage = () => {
    const [fileBannerHeader, setFileBannerHeader] = useState<UploadFile[]>([]);
    const [fileBannerBlog1, setFileBannerBlog1] = useState<UploadFile[]>([]);
    const [fileBannerBlog2, setFileBannerBlog2] = useState<UploadFile[]>([]);
    const [fileBannerBlog3, setFileBannerBlog3] = useState<UploadFile[]>([]);
    const [fileBannerSlider1, setFileBannerSlider1] = useState<UploadFile[]>([]);
    const [fileBannerSlider2, setFileBannerSlider2] = useState<UploadFile[]>([]);
    const [fileBannerSlider3, setFileBannerSlider3] = useState<UploadFile[]>([]);
    const [fileBannerSlider4, setFileBannerSlider4] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [setting, setSetting] = useState<ISetting>();
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        axios.get(getApiUrl(`/setting`)
            , headerConfig()).then((response: any) => {
                if (response.data) {
                    setSetting(response.data)
                    form.setFieldsValue(response.data);
                }
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onSave = (values: any) => {
        const headerRequest = headerConfig();
        setLoadingButton(true);

        if (fileBannerHeader[0]) {
            form.setFieldValue("banner_header", fileBannerHeader[0].originFileObj);
        }
        if (fileBannerBlog1[0]) {
            form.setFieldValue("banner_blog1", fileBannerBlog1[0].originFileObj);
        }
        if (fileBannerBlog2[0]) {
            form.setFieldValue("banner_blog2", fileBannerBlog2[0].originFileObj);
        }
        if (fileBannerBlog3[0]) {
            form.setFieldValue("banner_blog3", fileBannerBlog3[0].originFileObj);
        }
        if (fileBannerSlider1[0]) {
            form.setFieldValue("banner_slider1", fileBannerSlider1[0].originFileObj);
        }
        if (fileBannerSlider2[0]) {
            form.setFieldValue("banner_slider2", fileBannerSlider2[0].originFileObj);
        }
        if (fileBannerSlider3[0]) {
            form.setFieldValue("banner_slider3", fileBannerSlider3[0].originFileObj);
        }
        if (fileBannerSlider4[0]) {
            form.setFieldValue("banner_slider4", fileBannerSlider4[0].originFileObj);
        }

        const formData = new FormData();
        formData.append("title", form.getFieldValue('title'));
        formData.append("description", form.getFieldValue('description'));
        formData.append("shortDesc", form.getFieldValue('shortDesc'));
        formData.append("address1", form.getFieldValue('address1'));
        formData.append("address2", form.getFieldValue('address2'));
        formData.append("address3", form.getFieldValue('address3'));
        form.getFieldValue('phone1') && formData.append("phone1", form.getFieldValue('phone1'));
        form.getFieldValue('phone2') && formData.append("phone2", form.getFieldValue('phone2'));
        form.getFieldValue('phone3') && formData.append("phone3", form.getFieldValue('phone3'));
        form.getFieldValue('landLinePhone1') && formData.append("landLinePhone1", form.getFieldValue('landLinePhone1'));
        form.getFieldValue('landLinePhone2') && formData.append("landLinePhone2", form.getFieldValue('landLinePhone2'));
        formData.append("ga", form.getFieldValue('ga'));
        formData.append("responseTime", form.getFieldValue('responseTime'));
        formData.append("apikeyIdpay", form.getFieldValue('apikeyIdpay'));
        formData.append("userSms", form.getFieldValue('userSms'));
        formData.append("passwordSms", form.getFieldValue('passwordSms'));
        formData.append("forgotPasswordPattern", form.getFieldValue('forgotPasswordPattern'));
        formData.append("orderUserPattern", form.getFieldValue('orderUserPattern'));
        formData.append("orderAdminPattern", form.getFieldValue('orderAdminPattern'));
        formData.append("fromNum", form.getFieldValue('fromNum'));
        formData.append("adminName", form.getFieldValue('adminName'));
        formData.append("banner_header", form.getFieldValue('banner_header'));
        formData.append("link_header", form.getFieldValue('link_header'));
        formData.append("banner_blog1", form.getFieldValue('banner_blog1'));
        formData.append("link_blog1", form.getFieldValue('link_blog1'));
        formData.append("banner_blog2", form.getFieldValue('banner_blog2'));
        formData.append("link_blog2", form.getFieldValue('link_blog2'));
        formData.append("banner_blog3", form.getFieldValue('banner_blog3'));
        formData.append("link_blog3", form.getFieldValue('link_blog3'));
        formData.append("banner_slider1", form.getFieldValue('banner_slider1'));
        formData.append("link_slider1", form.getFieldValue('link_slider1'));
        formData.append("banner_slider2", form.getFieldValue('banner_slider2'));
        formData.append("link_slider2", form.getFieldValue('link_slider2'));
        formData.append("banner_slider3", form.getFieldValue('banner_slider3'));
        formData.append("link_slider3", form.getFieldValue('link_slider3'));
        formData.append("banner_slider4", form.getFieldValue('banner_slider4'));
        formData.append("link_slider4", form.getFieldValue('link_slider4'));
        formData.append("shippingPostDesc", form.getFieldValue('shippingPostDesc'));
        formData.append("shippingPostCost", form.getFieldValue('shippingPostCost'));
        formData.append("shippingPostFreeCost", form.getFieldValue('shippingPostFreeCost'));
        formData.append("xSandbox", form.getFieldValue('xSandbox'));
        formData.append("url", form.getFieldValue('url'));  
        formData.append("paymentGateway", form.getFieldValue('paymentGateway') || false);
        form.getFieldValue('instagram') &&  formData.append("instagram", form.getFieldValue('instagram'));
        form.getFieldValue('telegram') &&  formData.append("telegram", form.getFieldValue('telegram'));
        form.getFieldValue('whatsapp') &&  formData.append("whatsapp", form.getFieldValue('whatsapp'));
        form.getFieldValue('bale') &&  formData.append("bale", form.getFieldValue('bale'));
        form.getFieldValue('eita') &&  formData.append("eita", form.getFieldValue('eita'));
        form.getFieldValue('rubika') &&  formData.append("rubika", form.getFieldValue('rubika'));
        form.getFieldValue('sorosh') &&  formData.append("sorosh", form.getFieldValue('sorosh'));
        form.getFieldValue('eNamad') &&  formData.append("eNamad", form.getFieldValue('eNamad'));
        form.getFieldValue('samandehi') &&  formData.append("samandehi", form.getFieldValue('samandehi'));
        form.getFieldValue('idpayNamad') &&  formData.append("idpayNamad", form.getFieldValue('idpayNamad'));      
        form.getFieldValue('chatOnline') &&  formData.append("chatOnline", form.getFieldValue('chatOnline'));     
        form.getFieldValue('festivalTitle') &&  formData.append("festivalTitle", form.getFieldValue('festivalTitle'));     
        formData.append("panelActive", form.getFieldValue('panelActive') || false);            
        formData.append("panelText", form.getFieldValue('panelText') || ' ');            
        formData.append("campainYektanet", form.getFieldValue('campainYektanet') || ' ');            
        formData.append("festivalActive", form.getFieldValue('festivalActive'));            
        formData.append("shippingPostActive", form.getFieldValue('shippingPostActive'));            
        formData.append("shippingTipaxActive", form.getFieldValue('shippingTipaxActive'));            
        formData.append("shippingPaykActive", form.getFieldValue('shippingPaykActive'));   
        formData.append("paymentType", form.getFieldValue('paymentType'));         

        axios({
            url: getApiUrl(`/setting`),
            method: 'post',
            data: formData,
            headers: headerRequest.headers
        }).then(response => {
            setLoadingButton(false);
            message.success('عملیات با موفقیت انجام شد.');
            form.resetFields();
            setFileBannerHeader([]);
            setFileBannerBlog1([]);
            setFileBannerBlog2([]);
            setFileBannerBlog3([]);
            setFileBannerSlider1([]);
            setFileBannerSlider2([]);
            setFileBannerSlider3([]);
            setFileBannerSlider4([]);
            getData();
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoadingButton(false);
        });
    }

    const onChangeBannerHeader: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerHeader(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerHeader = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }
    const onChangeBannerBlog1: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerBlog1(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerBlog1 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerBlog2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerBlog2(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerBlog2 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerBlog3: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerBlog3(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerBlog3 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerSlider1: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerSlider1(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerSlider1 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerSlider2: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerSlider2(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerSlider2 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerSlider3: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerSlider3(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerSlider3 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeBannerSlider4: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileBannerSlider4(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewBannerSlider4 = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    return <Layout>
        <Spin spinning={loading}>
            <Form
                name="setting"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off"
                onFinish={onSave}>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="عکس بنری بالای سایت" key="1">
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_header"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerHeader}
                                onChange={onChangeBannerHeader}
                                onPreview={onPreviewBannerHeader}
                            >
                                {fileBannerHeader.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_header && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_header}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_header"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                    </Panel>
                    <Panel header="عکس بنری پایین اسلایدر" key="2">
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_slider1"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerSlider1}
                                onChange={onChangeBannerSlider1}
                                onPreview={onPreviewBannerSlider1}
                            >
                                {fileBannerSlider1.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_slider1 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_slider1}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_slider1"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_slider2"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerSlider2}
                                onChange={onChangeBannerSlider2}
                                onPreview={onPreviewBannerSlider2}
                            >
                                {fileBannerSlider2.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_slider2 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_slider2}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_slider2"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_slider3"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerSlider3}
                                onChange={onChangeBannerSlider3}
                                onPreview={onPreviewBannerSlider3}
                            >
                                {fileBannerSlider3.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_slider3 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_slider3}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_slider3"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_slider4"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerSlider4}
                                onChange={onChangeBannerSlider4}
                                onPreview={onPreviewBannerSlider4}
                            >
                                {fileBannerSlider4.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_slider4 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_slider4}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_slider4"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                    </Panel>
                    <Panel header="عکس بنری بالای آخرین مقالات" key="3">
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_blog1"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerBlog1}
                                onChange={onChangeBannerBlog1}
                                onPreview={onPreviewBannerBlog1}
                            >
                                {fileBannerBlog1.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_blog1 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_blog1}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_blog1"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                    </Panel>
                    <Panel header="عکس بنری پایین جشنواره" key="4">
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_blog2"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerBlog2}
                                onChange={onChangeBannerBlog2}
                                onPreview={onPreviewBannerBlog2}
                            >
                                {fileBannerBlog2.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_blog2 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_blog2}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_blog2"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                        <Form.Item
                            label="آپلود عکس"
                            name="banner_blog3"
                        >
                            <Upload
                                listType="picture-card"
                                fileList={fileBannerBlog3}
                                onChange={onChangeBannerBlog3}
                                onPreview={onPreviewBannerBlog3}
                            >
                                {fileBannerBlog3.length < 1 && '+ بارگزاری عکس'}
                            </Upload>
                            {setting?.banner_blog3 && <Space>
                                <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                                <Avatar src={`${baseUrl}/${setting?.banner_blog3}`} size={64} />
                            </Space>}
                        </Form.Item>
                        <Form.Item
                            label="لینک بنر"
                            name="link_blog3"
                        >
                            <Input placeholder='لینک بنر' />
                        </Form.Item>
                    </Panel>
                </Collapse>

                <Form.Item style={{ marginTop: 20 }}>
                    <Button type="primary" htmlType="submit" loading={loadingButton}>
                        ذخیره
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </Layout>
}

export default SettingPage;