import { Avatar, message, Popconfirm, Space, Spin, Table, Typography, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig, baseUrl } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { CreateProduct, Price } from './Components';
import { IProduct, ICategory, IDiscount } from '../../../Models';
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const ProductPage = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [discount, setDiscount] = useState<Array<IDiscount>>([]);
    const [category, setCategory] = useState<Array<ICategory>>([]);
    const empty: IProduct = {
        title: '', description: '', shortDesc: '', _id: '', picture: '', count: 0, showInHomePage: false,
        category: { _id: '', title: '', breadCrumb: '' }, price: 0, images: '', variety: '', discount: { _id: '', title: '' },
        festival: false
    };
    const [record, setRecord] = useState<IProduct>(empty);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
        getCategory();
        getDiscount();
    }, []);

    const getDiscount = () => {
        axios.get(getApiUrl(`/discount/getAllForProduct`)
            , headerConfig()).then((response: any) => {
                setDiscount(response.data);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
            })
    }

    useEffect(() => {
        if (!open) {
            setRecord(empty);
        }
    }, [open]);

    const getCategory = () => {
        axios.get(getApiUrl(`/category/getAll`)
            , headerConfig()).then((response: any) => {
                setCategory(response.data);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
            })
    }

    const deleteProduct = (id: string) => {
        setLoading(true);
        axios.delete(getApiUrl(`/product/${id}`), headerConfig()).then(response => {
            message.success('عملیات با موفقیت انجام شد.');
            let current: number = tableParams.pagination.current || 1;
            if (tableParams.pagination.current !== 1 && products.length === 1) {
                current = current - 1
            }
            getData({ ...tableParams.pagination, current });
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        })
    }

    const editCategory = (record: IProduct) => {
        setRecord(record);
        setOpen(true);
    }

    const columns: ColumnsType<IProduct> = [
        {
            title: 'عکس',
            dataIndex: 'picture',
            key: 'picture',
            render: (_, record) => (
                <Avatar src={`${baseUrl}/${record.picture}`} size={40} />
            ),
            width: 50,
            responsive: ["md", "lg"],
        },
        {
            title: 'عنوان',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.title}
                />
            ),
            width: 250
        },
        {
            title: 'پرفروش ترین ها',
            dataIndex: 'showInHomePage',
            key: 'showInHomePage',
            render: (_, record) => (
                record.showInHomePage ? <Text type='success'><CheckOutlined  rev={'span'}/></Text> :
                    <Text type="danger"><CloseOutlined rev={'span'} /></Text>
            ),
            width: 40,
            responsive: ["md", "lg"],
        },
        {
            title: 'جشنواره',
            dataIndex: 'festival',
            key: 'festival',
            render: (_, record) => (
                record.festival ? <Text type='success'><CheckOutlined  rev={'span'}/></Text> :
                    <Text type="danger"><CloseOutlined rev={'span'} /></Text>
            ),
            width: 40,
            responsive: ["md", "lg"],
        },
        {
            title: 'قیمت',
            dataIndex: 'price',
            key: 'price',
            width: 80,
            render: (_, record) => (
                <Price product={record} />
            ),

            responsive: ["md", "lg"],
        },
        {
            title: 'دسته بندی',
            dataIndex: 'category',
            key: 'category',
            render: (_, record) => (
                <div>{record.category.breadCrumb}</div>
            ),
            width: 100,
            responsive: ["md", "lg"],
        },
        {
            title: 'تعداد',
            dataIndex: 'count',
            key: 'count',
            width: 80,
            responsive: ["md", "lg"],
        },
        {
            title: 'تخفیف',
            dataIndex: 'discount',
            key: 'discount',
            width: 80,
            render: (_, record) => (
                <div>{record.discount?.title}</div>
            ),
            responsive: ["md", "lg"],
        },
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => editCategory(record)}>ویرایش</a>
                    <Popconfirm
                        placement="top"
                        title={'حذف محصول'}
                        description={'آیا مطمئن هستید؟؟'}
                        onConfirm={() => deleteProduct(record._id)}
                        okText="بله"
                        cancelText="خیر"
                    >
                        <a><Text type="danger">حذف</Text></a>
                    </Popconfirm>
                </Space>
            ),
            width: 150
        },
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/product?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setProducts(response.data.products);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <CreateProduct open={open} setOpen={setOpen} record={record} getData={() => getData(tableParams.pagination)} category={category} discount={discount} />
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={products}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default ProductPage;