import { message, Popconfirm, Space, Table, Typography, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { CreatePage } from './Components';
import { IPages } from '../../../Models';
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const FaqPage = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [pages, setPages] = useState<Array<IPages>>([]);
    const empty: IPages = { title: '', description: '', _id: '', active:false};
    const [record, setRecord] = useState<IPages>(empty);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);

    useEffect(() => {
        if (!open) {
            setRecord(empty);
        }
    }, [open]);

    const deletePage = (id: string) => {
        setLoading(true);
        axios.delete(getApiUrl(`/pages/${id}`), headerConfig()).then(response => {
            message.success('عملیات با موفقیت انجام شد.');
            let current: number = tableParams.pagination.current || 1;
            if (tableParams.pagination.current !== 1 && pages.length === 1) {
                current = current - 1
            }
            getData({ ...tableParams.pagination, current });
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        })
    }

    const editPage = (record: IPages) => {
        setRecord(record);
        setOpen(true);
    }

    const columns: ColumnsType<IPages> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.title}
                />
            ),
            width: 250
        },
        {
            title: 'فعال/غیرفعال',
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
                record.active ? <Text type='success'><CheckOutlined rev={'span'} /></Text> :
                    <Text type="danger"><CloseOutlined rev={'span'} /></Text>
            ),
            width: 80
        }, 
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => editPage(record)}>ویرایش</a>
                    <Popconfirm
                        placement="top"
                        title={'حذف صفحه'}
                        description={'آیا مطمئن هستید؟؟'}
                        onConfirm={() => deletePage(record._id)}
                        okText="بله"
                        cancelText="خیر"
                    >
                        <a><Text type="danger">حذف</Text></a>
                    </Popconfirm>
                </Space>
            ),
            width: 150
        },
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/pages?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setPages(response.data.pages);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <CreatePage open={open} setOpen={setOpen} record={record} getData={() => getData(tableParams.pagination)} />
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={pages}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default FaqPage;