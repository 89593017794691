import { message, Space, Table, Typography, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig, baseUrl } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { IOrders } from '../../../Models';
import moment from 'jalali-moment';
import { ViewOrder } from './Components'
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const CategoryPage = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const empty: IOrders = {
        code: '', _id: '', status: 0, createDate: '', totalPrice: 0, shippingCost: 0, shippingAddress: '', shippingMethod: '', detail: [], user: { _id: '', name: '', phone: '' }
    }
    const [record, setRecord] = useState<IOrders>(empty);
    const [search, setSearch] = useState<string>('');
    const [orders, setOrders] = useState<Array<IOrders>>([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);

    const viewOrder = (record: IOrders) => {
        setRecord(record);
        setOpen(true);
    }

    const columns: ColumnsType<IOrders> = [
        {
            title: 'تاریخ سفارش',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => (
                <div>{moment(record.createDate).locale('fa').format('YYYY/MM/DD HH:mm')}</div>
            ),
            width: 100
        },
        {
            title: 'کد سفارش',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.code}
                />
            ),
            width: 100
        },
        {
            title: 'نام سفارش دهنده',
            dataIndex: 'birthDay',
            key: 'birthDay',
            render: (_, record) => (
                <div>{record.user.name}</div>
            ),
            width: 100
        },
        {
            title: 'شماره تلفن',
            dataIndex: 'phone',
            key: 'phone',
            responsive: ["md", "lg"],
            render: (_, record) => (
                <div>{record.user.phone}</div>
            ),
            width: 100
        },
        {
            title: 'وضعیت سفارش',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <Fragment>
                    <Text type='danger'>{record.status === 0 && 'در حال آماده سازی'}</Text>
                    <Text type='warning'>{record.status === 1 && 'تحویل به پست'}</Text>
                    <Text type='warning'>{record.status === 2 && 'تحویل به پیک'}</Text>
                    <Text type='warning'>{record.status === 3 && 'تحویل به تیپاکس'}</Text>
                    <Text type='success'>{record.status === 4 && 'تحویل به مشتری'}</Text>
                    <div>{record.status === -1 && '...'}</div>
                </Fragment>
            ),
            responsive: ["md", "lg"],
            width: 100
        },
        {
            title: 'مبلغ سفارش',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            responsive: ["md", "lg"],
            width: 100,
            render: (_, record) => (
                <Space>
                    <span>{new Intl.NumberFormat().format(record.totalPrice)}</span>
                    <span>تومان</span>
                </Space>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <Fragment>
                    <Text type='success'>{record.status !== -1 && 'پرداخت شده'}</Text>
                    <Text type='danger'>{record.status === -1 && 'پرداخت نشده'}</Text>
                </Fragment>
            ),
            responsive: ["md", "lg"],
            width: 100
        },        
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => viewOrder(record)}>مشاهده</a>
                </Space>
            ),
            width: 100
        },
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/order/?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setOrders(response.data.orders);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <ViewOrder open={open} setOpen={setOpen} record={record} getData={() => getData(tableParams.pagination)} />
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={orders}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default CategoryPage;