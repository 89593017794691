import { message, Space, Table, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { IOffer } from '../../../Models';

interface TableParams {
    pagination: TablePaginationConfig
}

const OfferPage = () => {

    const navigate = useNavigate();
    const [search, setSearch] = useState<string>('');
    const [offers, setOffers] = useState<Array<IOffer>>([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);


    const columns: ColumnsType<IOffer> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.title}
                />
            ),
            width: 150
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                <Fragment>
                    <div>{record.type === 0 && 'انتقاد'}</div>
                    <div>{record.type === 1 && 'پیشنهاد'}</div>
                    <div>{record.type === 2 && 'سوال'}</div>
                </Fragment>
            ),
            width: 50
        },
        {
            title: 'انتقاد یا پیشنهاد',
            dataIndex: 'description',
            key: 'description',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.description}
                />
            ),
            width: 150,
        },
        {
            title: 'ایجاد کننده',
            dataIndex: 'creator',
            key: 'creator',
            width: 80,
            responsive: ["md", "lg"],
        },
        {
            title: 'شماره تماس',
            dataIndex: 'phone',
            key: 'phone',
            width: 80,
            responsive: ["md", "lg"],
        }
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/offer?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                setOffers(response.data.offers);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={offers}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default OfferPage;