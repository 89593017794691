import { Button, Form, Input, message, Spin } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import { useSelector, shallowEqual } from "react-redux";
import { IUser } from '../../../Models';

const ChangePasswordPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const user: IUser = useSelector(
        (state: any) => state.user,
        shallowEqual
    );

    const onSave = (values: any) => {
        const headerRequest = headerConfig();
        setLoading(true);
        if (values.newPassword !== values.confirmPassword)
            return message.error('رمز عبور جدید و تکرار آن باهم مطابقت ندارد.');

        axios({
            url: getApiUrl(`/users/changePassword`),
            method: 'post',
            data: { ...values, id: user?._id || '' },
            headers: headerRequest.headers
        }).then(response => {
            setLoading(false);
            message.success('عملیات با موفقیت انجام شد.');
            const headers = response.headers['x-auth-token'];
            localStorage.setItem('token', headers);
            form.resetFields();
        }).catch(error => {
             if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        });
    }

    return <Layout>
        <Spin spinning={loading}>
            <Form
                name="changePassword"
                form={form}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                autoComplete="off"
                onFinish={onSave}>
                <Form.Item
                    label="رمز عبور قبلی"
                    name="oldPassword"
                    rules={[{ required: true, message: 'مقدار رمز عبور قبلی نمیتواند خالی باشد.' }]}
                >
                    <Input.Password placeholder='رمز عبور قبلی' />
                </Form.Item>
                <Form.Item
                    label="رمز عبور جدید"
                    name="newPassword"
                    rules={[{ required: true, message: 'مقدار رمز عبور جدید نمیتواند خالی باشد.' }]}
                >
                    <Input.Password placeholder='رمز عبور جدید' />
                </Form.Item>
                <Form.Item
                    label="تکرار رمز عبور جدید"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'مقدار تکرار رمز عبور جدید نمیتواند خالی باشد.' }]}
                >
                    <Input.Password placeholder='تکرار رمز عبور جدید' />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        ذخیره
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </Layout>
}

export default ChangePasswordPage;