import { Avatar, message, Popconfirm, Space, Spin, Table, Typography, Input, TablePaginationConfig } from 'antd';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { getApiUrl, headerConfig } from '../../../Helpers';
import Layout from '../../Layout';
import type { ColumnsType } from 'antd/es/table';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CreateDiscount } from './Components';
import moment from 'jalali-moment';
import { IDiscount } from '../../../Models';
const { Text } = Typography;

interface TableParams {
    pagination: TablePaginationConfig
}

const DiscountPage = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [discounts, setDiscounts] = useState<Array<IDiscount>>([]);
    const empty: IDiscount = { title: '', _id: '', createDate: '', type: 0, amount: 0, usePercentage: false, code: '', limitation: 0, limitationTimes: 0, startDate: '', endDate: '',minimumOrder:0 };
    const [record, setRecord] = useState<IDiscount>(empty);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
    });

    useEffect(() => {
        getData(tableParams.pagination);
    }, []);

    useEffect(() => {
        if (!open) {
            setRecord(empty);
        }
    }, [open]);

    const deleteDiscount = (id: string) => {
        setLoading(true);
        axios.delete(getApiUrl(`/discount/${id}`), headerConfig()).then(response => {
            message.success('عملیات با موفقیت انجام شد.');
            let current: number = tableParams.pagination.current || 1;
            if (tableParams.pagination.current !== 1 && discounts.length === 1) {
                current = current - 1
            }
            getData({ ...tableParams.pagination, current });
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoading(false);
        })
    }

    const editDiscount = (record: IDiscount) => {
        setRecord(record);
        setOpen(true);
    }

    const columns: ColumnsType<IDiscount> = [
        {
            title: 'عنوان',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={record.title}
                />
            ),
            width: 200
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => (
                <Fragment>
                    <div>{record.type === 0 && <label>دسته بندی</label>}</div>
                    <div>{record.type === 1 && <label>محصولات</label>}</div>
                    <div>{record.type === 2 && <label>مجموع سفارش</label>}</div>
                </Fragment>
            ),
            width: 100,
            responsive: ["md", "lg"],
        },
        {
            title: 'مقدار',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
            render: (_, record) => (
                <div>
                    <label>{record.amount}</label>
                    <label>{record.usePercentage ? ' درصد ' : ' تومان '}</label>
                </div>
            ),
            responsive: ["md", "lg"],
        },
        {
            title: 'استفاده از درصد',
            dataIndex: 'usePercentage',
            key: 'usePercentage',
            width: 100,
            render: (_, record) => (
                record.usePercentage ? <Text type='success'><CheckOutlined  rev={'span'} /></Text> :
                    <Text type="danger"><CloseOutlined rev={'span'} /></Text>
            ),
            responsive: ["md", "lg"],
        },
        // {
        //     title: 'تاریخ شروع',
        //     dataIndex: 'startDate',
        //     key: 'startDate',
        //     width: 100,
        //     render: (_, record) => (
        //         <div>{record.type === 2 && moment(record.startDate).locale('fa').format('YYYY/MM/DD')}</div>
        //     ),
        //     responsive: ["md", "lg"],
        // },
        // {
        //     title: 'تاریخ پایان',
        //     dataIndex: 'endDate',
        //     key: 'endDate',
        //     width: 100,
        //     render: (_, record) => (
        //         <div>{record.type === 2 && moment(record.endDate).locale('fa').format('YYYY/MM/DD')}</div>
        //     ),
        //     responsive: ["md", "lg"],
        // },
        // {
        //     title: 'تعداد قابل استفاده',
        //     dataIndex: 'limitationTimes',
        //     key: 'limitationTimes',
        //     width: 100,

        //     responsive: ["md", "lg"],
        // },
        {
            title: 'عملیات',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => editDiscount(record)}>ویرایش</a>
                    <Popconfirm
                        placement="top"
                        title={'حذف تخفیف'}
                        description={'آیا مطمئن هستید؟؟'}
                        onConfirm={() => deleteDiscount(record._id)}
                        okText="بله"
                        cancelText="خیر"
                    >
                        <a><Text type="danger">حذف</Text></a>
                    </Popconfirm>
                </Space>
            ),
            width: 150
        },
    ]

    const getData = (pagination: TablePaginationConfig) => {
        setLoading(true);
        axios.get(getApiUrl(`/discount?search=${search}&pageSize=${pagination.pageSize}&pageNumber=${pagination.current}`)
            , headerConfig()).then((response: any) => {
                console.log(response.data.discount);
                setDiscounts(response.data.discount);
                setTableParams({
                    pagination: {
                        pageSize: pagination.pageSize,
                        current: pagination.current,
                        total: response.data.totalCount
                    },
                });
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onChange = (event: any) => {
        setSearch(event.target.value);
    }

    const handleTableChange = (pagination: TablePaginationConfig) => {
        getData(pagination);
    }

    const onSearch = () => {
        const pagination = tableParams.pagination;
        getData({ ...pagination, current: 1 });
    }

    return <Layout>
        <Space className='panel-search'>
            <CreateDiscount open={open} setOpen={setOpen} record={record} getData={() => getData(tableParams.pagination)} />
            <Input.Search value={search} onChange={onChange} onSearch={onSearch}
                placeholder="جستجو ..." allowClear style={{ width: 200 }} enterButton />
        </Space>
        <Table
            columns={columns}
            dataSource={discounts}
            pagination={tableParams.pagination}
            rowKey={(record) => record._id}
            loading={loading}
            onChange={handleTableChange} />
    </Layout>
}

export default DiscountPage;