import { Alert, Avatar, Button, Checkbox, Collapse, Form, Input, message, Radio, Space, Spin, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISetting } from '../../../Models'
import { baseUrl, getApiUrl, headerConfig, quiil } from '../../../Helpers';
import Layout from '../../Layout';
import { RcFile } from 'antd/es/upload';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { Panel } = Collapse;

const SettingPage = () => {
    const [fileListLogo, setFileListLogo] = useState<UploadFile[]>([]);
    const [fileListIcon, setFileListIcon] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [setting, setSetting] = useState<ISetting>();
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        axios.get(getApiUrl(`/setting`)
            , headerConfig()).then((response: any) => {
                if (response.data) {
                    setSetting(response.data)
                    form.setFieldsValue(response.data);
                }
                setLoading(false);
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            })
    }

    const onSave = (values: any) => {
        const headerRequest = headerConfig();
        setLoadingButton(true);

        if (fileListLogo[0]) {
            form.setFieldValue("logo", fileListLogo[0].originFileObj);
        }
        if (fileListIcon[0]) {
            form.setFieldValue("icon", fileListIcon[0].originFileObj);
        }

        const formData = new FormData();
        formData.append("title", values.title.trim());
        formData.append("description", values.description || ' ');
        formData.append("shortDesc", values.shortDesc || ' ');
        formData.append("address1", values.address1 || ' ');
        formData.append("address2", values.address2 || ' ');
        formData.append("address3", values.address3 || ' ');
        values.phone1 && formData.append("phone1", values.phone1);
        values.phone2 && formData.append("phone2", values.phone2);
        values.phone3 && formData.append("phone3", values.phone3);
        values.landLinePhone1 && formData.append("landLinePhone1", values.landLinePhone1);
        values.landLinePhone2 && formData.append("landLinePhone2", values.landLinePhone2);
        formData.append("ga", values.ga || ' ');
        formData.append("responseTime", values.responseTime || ' ');
        formData.append("apikeyIdpay", values.apikeyIdpay || ' ');
        formData.append("userSms", values.userSms || ' ');
        formData.append("passwordSms", values.passwordSms || ' ');
        formData.append("forgotPasswordPattern", values.forgotPasswordPattern || ' ');
        formData.append("orderUserPattern", values.orderUserPattern || ' ');
        formData.append("orderAdminPattern", values.orderAdminPattern || ' ');
        formData.append("fromNum", values.fromNum || ' ');
        formData.append("adminName", values.adminName || ' ');
        formData.append("url", values.url || ' ');
        formData.append("xSandbox", values.xSandbox || false);
        formData.append("logo", form.getFieldValue('logo') || ' ');
        formData.append("icon", form.getFieldValue('icon') || ' ');
        formData.append("link_slider4", form.getFieldValue('link_slider4') || ' ');
        formData.append("link_slider3", form.getFieldValue('link_slider3') || ' ');
        formData.append("link_slider2", form.getFieldValue('link_slider2') || ' ');
        formData.append("link_slider1", form.getFieldValue('link_slider1') || ' ');
        formData.append("link_blog3", form.getFieldValue('link_blog3') || ' ');
        formData.append("link_blog2", form.getFieldValue('link_blog2') || ' ');
        formData.append("link_blog1", form.getFieldValue('link_blog1') || ' ');
        formData.append("link_header", form.getFieldValue('link_header') || ' ');
        formData.append("shippingPostDesc", values.shippingPostDesc || ' ');
        formData.append("shippingPostCost", values.shippingPostCost || ' ');
        formData.append("shippingPaykDesc", values.shippingPaykDesc || ' ');
        formData.append("shippingPaykCost", values.shippingPaykCost || ' ');
        formData.append("shippingTipaxDesc", values.shippingTipaxDesc || ' ');
        formData.append("shippingTipaxCost", values.shippingTipaxCost || ' ');
        formData.append("shippingPostFreeCost", values.shippingPostFreeCost || ' ');
        formData.append("paymentGateway", values.paymentGateway || false);
        values.instagram && formData.append("instagram", values.instagram);
        values.telegram && formData.append("telegram", values.telegram);
        values.whatsapp && formData.append("whatsapp", values.whatsapp);
        values.bale && formData.append("bale", values.bale);
        values.eita && formData.append("eita", values.eita);
        values.rubika && formData.append("rubika", values.rubika);
        values.sorosh && formData.append("sorosh", values.sorosh);
        values.eNamad && formData.append("eNamad", values.eNamad);
        values.samandehi && formData.append("samandehi", values.samandehi);
        values.idpayNamad && formData.append("idpayNamad", values.idpayNamad);
        values.chatOnline && formData.append("chatOnline", values.chatOnline);
        formData.append("panelActive", values.panelActive || false);
        formData.append("panelText", values.panelText || ' ');
        formData.append("campainYektanet", values.campainYektanet || ' ');
        values.festivalTitle && formData.append("festivalTitle", values.festivalTitle);
        formData.append("festivalActive", values.festivalActive || false);
        formData.append("shippingPostActive", values.shippingPostActive || false);
        formData.append("shippingPaykActive", values.shippingPaykActive || false);
        formData.append("shippingTipaxActive", values.shippingTipaxActive || false);
        formData.append("paymentType", values.paymentType || 'idpay');

        axios({
            url: getApiUrl(`/setting`),
            method: 'post',
            data: formData,
            headers: headerRequest.headers
        }).then(response => {
            setLoadingButton(false);
            message.success('عملیات با موفقیت انجام شد.');
            form.resetFields();
            setFileListLogo([]);
            setFileListIcon([]);
            getData();
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                return navigate('/login');
            }
            message.error(error.response.data.message);
            setLoadingButton(false);
        });
    }

    const onChangeLogo: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileListLogo(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewLogo = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    const onChangeIcon: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileListIcon(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreviewIcon = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    return <Layout>
        <Spin spinning={loading}>
            <Form
                name="createPost"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off"
                onFinish={onSave}>
                <Form.Item
                    label="عنوان سایت"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان سایت نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان سایت' />
                </Form.Item>
                <Form.Item
                    label="توضیح کوتاه"
                    name="shortDesc"
                >
                    <TextArea placeholder="توضیح کوتاه" autoSize />
                </Form.Item>
                <Form.Item
                    label="آدرس اول"
                    name="address1"
                >
                    <Input placeholder='آدرس اول' />
                </Form.Item>
                <Form.Item
                    label="آدرس دوم"
                    name="address2"
                >
                    <Input placeholder='آدرس دوم' />
                </Form.Item>
                <Form.Item
                    label="آدرس سوم"
                    name="address3"
                >
                    <Input placeholder='آدرس سوم' />
                </Form.Item>
                <Form.Item
                    label="شماره ثابت اول"
                    name="landLinePhone1"
                >
                    <Input type='number' placeholder='شماره ثابت اول' />
                </Form.Item>
                <Form.Item
                    label="شماره ثابت دوم"
                    name="landLinePhone2"
                >
                    <Input type='number' placeholder='شماره ثابت دوم' />
                </Form.Item>
                <Form.Item
                    label="شماره همراه اول"
                    name="phone1"
                    rules={[
                        { pattern: new RegExp("^09\\d{9}$"), message: 'ساختار شماره تلفن صحیح نمیباشد.' },
                    ]}
                >
                    <Input type='number' placeholder='شماره همراه اول' />
                </Form.Item>
                <Form.Item
                    label="شماره همراه دوم"
                    name="phone2"
                    rules={[
                        { pattern: new RegExp("^09\\d{9}$"), message: 'ساختار شماره تلفن صحیح نمیباشد.' },
                    ]}
                >
                    <Input type='number' placeholder='شماره همراه دوم' />
                </Form.Item>
                <Form.Item
                    label="شماره همراه سوم"
                    name="phone3"
                    rules={[
                        { pattern: new RegExp("^09\\d{9}$"), message: 'ساختار شماره تلفن صحیح نمیباشد.' },
                    ]}
                >
                    <Input type='number' placeholder='شماره همراه سوم' />
                </Form.Item>
                <Form.Item
                    label="ساعت پاسخ گویی"
                    name="responseTime"
                >
                    <Input placeholder='ساعت پاسخ گویی' />
                </Form.Item>
                <Form.Item
                    label="نام ادمین سایت"
                    name="adminName"
                >
                    <Input placeholder='نام ادمین سایت' />
                </Form.Item>
                <Form.Item
                    label="آپلود لوگو"
                    name="logo"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileListLogo}
                        onChange={onChangeLogo}
                        onPreview={onPreviewLogo}
                    >
                        {fileListLogo.length < 1 && '+ بارگزاری لوگو'}
                    </Upload>
                    {setting?.logo && <Space>
                        <Alert type="warning" message="اگر لوگویی آپلود کنید جایگزین لوگو قبلی میشود." />
                        <Avatar src={`${baseUrl}/${setting?.logo}`} size={64} />
                    </Space>}
                </Form.Item>
                <Form.Item
                    label="آپلود آیکون"
                    name="icon"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileListIcon}
                        onChange={onChangeIcon}
                        onPreview={onPreviewIcon}
                    >
                        {fileListIcon.length < 1 && '+ بارگزاری آیکون'}
                    </Upload>
                    {setting?.icon && <Space>
                        <Alert type="warning" message="اگر آیکونی آپلود کنید جایگزین آیکون قبلی میشود." />
                        <Avatar src={`${baseUrl}/${setting?.icon}`} size={64} />
                    </Space>}
                </Form.Item>
                <Form.Item
                    label="درباره ما"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
                <Form.Item
                    label="گوگل آنالیتیکس"
                    name="ga"
                >
                    <Input placeholder='گوگل آنالیتیکس' />
                </Form.Item>
                <Form.Item
                    label="site url"
                    name="url"
                >
                    <Input placeholder='site url' />
                </Form.Item>
                <Form.Item
                    label="نام کاربری فراز اس ام اس"
                    name="userSms"
                >
                    <Input placeholder='نام کاربری فراز اس ام اس' />
                </Form.Item>
                <Form.Item
                    label="رمز عبور فراز اس ام اس"
                    name="passwordSms"
                >
                    <Input placeholder='رمز عبور فراز اس ام اس' />
                </Form.Item>
                <Form.Item
                    label="شماره ارسال پیامک"
                    name="fromNum"
                >
                    <Input placeholder='شماره ارسال پیامک' />
                </Form.Item>
                <Form.Item
                    label="پترن فراموشی رمز عبور"
                    name="forgotPasswordPattern"
                >
                    <Input placeholder='پترن فراموشی رمز عبور' />
                </Form.Item>
                <Form.Item
                    label="پترن سفارش محصول(کاربر)"
                    name="orderUserPattern"
                >
                    <Input placeholder='پترن سفارش محصول(کاربر)' />
                </Form.Item>
                <Form.Item
                    label="پترن سفارش محصول(ادمین)"
                    name="orderAdminPattern"
                >
                    <Input placeholder='پترن سفارش محصول(ادمین)' />
                </Form.Item>
                <Form.Item name="paymentGateway" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن درگاه پرداخت</Checkbox>
                </Form.Item>                
                <Form.Item name="paymentType" label="روش پرداخت">
                    <Radio.Group >                        
                        <Radio value={'idpay'}>آیدی پی</Radio>
                        <Radio value={'zarinpal'}>زرین پال</Radio>                        
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="api key"
                    name="apikeyIdpay"
                >
                    <Input placeholder='api key' />
                </Form.Item>
                <Form.Item name="xSandbox" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>حالت آزمایشی درگاه پرداخت</Checkbox>
                </Form.Item>
                <Form.Item name="shippingPostActive" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن ارسال توسط پست</Checkbox>
                </Form.Item>
                <Form.Item
                    label="هزینه ارسال پست"
                    name="shippingPostCost"
                >
                    <Input type='number' placeholder='هزینه ارسال پست' />
                </Form.Item>
                <Form.Item
                    label="توضیحات پست"
                    name="shippingPostDesc"
                >
                    <Input placeholder='توضیحات پست' />
                </Form.Item>
                <Form.Item
                    label="ارسال رایگان پست از : "
                    name="shippingPostFreeCost"
                >
                    <Input type='number' placeholder='ارسال رایگان پست از : ' />
                </Form.Item>
                <Form.Item name="shippingTipaxActive" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن ارسال توسط تیپاکس</Checkbox>
                </Form.Item>
                <Form.Item
                    label="هزینه ارسال تیپاکس"
                    name="shippingTipaxCost"
                >
                    <Input type='number' placeholder='هزینه ارسال تیپاکس' />
                </Form.Item>
                <Form.Item
                    label="توضیحات تیپاکس"
                    name="shippingTipaxDesc"
                >
                    <Input placeholder='توضیحات تیپاکس' />
                </Form.Item>
                <Form.Item name="shippingPaykActive" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن ارسال توسط پیک</Checkbox>
                </Form.Item>
                <Form.Item
                    label="هزینه ارسال پیک"
                    name="shippingPaykCost"
                >
                    <Input type='number' placeholder='هزینه ارسال پیک' />
                </Form.Item>
                <Form.Item
                    label="توضیحات پیک"
                    name="shippingPaykDesc"
                >
                    <Input placeholder='توضیحات پیک' />
                </Form.Item>
                <Form.Item
                    label="اینستاگرام"
                    name="instagram"
                >
                    <Input placeholder='اینستاگرام' />
                </Form.Item>
                <Form.Item
                    label="تلگرام"
                    name="telegram"
                >
                    <Input placeholder='تلگرام' />
                </Form.Item>
                <Form.Item
                    label="واتس اپ"
                    name="whatsapp"
                >
                    <Input placeholder='واتس اپ' />
                </Form.Item>
                <Form.Item
                    label="ایتا"
                    name="eita"
                >
                    <Input placeholder='ایتا' />
                </Form.Item>
                <Form.Item
                    label="بله"
                    name="bale"
                >
                    <Input placeholder='بله' />
                </Form.Item>
                <Form.Item
                    label="سروش"
                    name="sorosh"
                >
                    <Input placeholder='سروش' />
                </Form.Item>
                <Form.Item
                    label="روبیکا"
                    name="rubika"
                >
                    <Input placeholder='روبیکا' />
                </Form.Item>

                <Form.Item
                    label="اینماد"
                    name="eNamad"
                >
                    <TextArea placeholder="اینماد" autoSize />
                </Form.Item>
                <Form.Item
                    label="ساماندهی"
                    name="samandehi"
                >
                    <TextArea placeholder="ساماندهی" autoSize />
                </Form.Item>
                <Form.Item
                    label="نماد آیدی پی"
                    name="idpayNamad"
                >
                    <TextArea placeholder="نماد آیدی پی" autoSize />
                </Form.Item>
                <Form.Item
                    label="چت آنلاین"
                    name="chatOnline"
                >
                    <TextArea placeholder="چت آنلاین" autoSize />
                </Form.Item>
                <Form.Item name="panelActive" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن پلن</Checkbox>
                </Form.Item>
                <Form.Item
                    label="عنوان صفحه پلن"
                    name="panelText"
                >
                    <Input placeholder='عنوان صفحه پلن' />
                </Form.Item>
                <Form.Item
                    label="کمپین یکتانت"
                    name="campainYektanet"
                >
                    <Input placeholder='کمپین یکتانت' />
                </Form.Item>
                <Form.Item name="festivalActive" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال بودن جشنواره</Checkbox>
                </Form.Item>
                <Form.Item
                    label="عنوان جشنواره"
                    name="festivalTitle"
                >
                    <Input placeholder='عنوان جشنواره' />
                </Form.Item>

                <Form.Item style={{ marginTop: 20 }}>
                    <Button type="primary" htmlType="submit" loading={loadingButton}>
                        ذخیره
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </Layout>
}

export default SettingPage;