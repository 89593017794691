import { Button, Checkbox, Form, Input, message, Modal } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApiUrl, headerConfig, quiil } from '../../../../../Helpers';
import { IPages } from '../../../../../Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: IPages
}

const CreateCategory = ({ getData, open, setOpen, record }: IProps) => {
 
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
            }
            else {
                form.resetFields();            
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);           
            const headerRequest = headerConfig();           
            const values = form.getFieldsValue();  
            if(!values.active) values.active = false;         
            axios({
                url: getApiUrl(`/pages/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: values,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();               
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }  

    const readImage = () => {
        navigator.clipboard.readText().then((text) => {
            const description = form.getFieldValue('description') || ''
            form.setFieldsValue({
                description: description + text
            });         
        })
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد صفحه جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد صفحه جدید' : `ویرایش صفحه (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            maskClosable={false}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
        >
            <Form name="createCategory"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                autoComplete="off">
                <Form.Item
                    label="عنوان صفحه"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان صفحه نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان صفحه' />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال / غیرفعال</Checkbox>
                </Form.Item>               
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <ReactQuill theme="snow" modules={quiil.modules} formats={quiil.formats} />
                </Form.Item>
                <Form.Item label="عکس بیشتر">
                    <Button onClick={readImage}>اضافه کردن عکس از کلیپ بورد</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreateCategory;