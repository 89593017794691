import { Fragment, useEffect, useState } from 'react';

const Price = ({ product }: any) => {

    const [percent, setPercent] = useState<number>(0);

    useEffect(() => {
        setPercent(100 - ((product.priceWithDiscount / product.price) * 100));
    }, [])

    return <Fragment>
        {
            product.price !== product.priceWithDiscount ? <Fragment>
                <div className='product-detail-priceWithDiscount'>
                    <span className='product-detail-badge'>{percent} % </span>
                    <del>{new Intl.NumberFormat().format(product.price)} تومان</del>
                </div>
                <div className='product-detail-price'>
                    <span>{new Intl.NumberFormat().format(product.priceWithDiscount)}</span>
                    <span>تومان</span>
                </div>
            </Fragment> : <div className='product-detail-price'>
                <span>{new Intl.NumberFormat().format(product.price)}</span>
                <span>تومان</span>
            </div>
        }
    </Fragment>
}

export default Price;