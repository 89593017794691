import ReactDOM from 'react-dom/client';
import './fonts/font-fa.css';
import './index.css';
import { ConfigProvider } from 'antd';
import { createStore, applyMiddleware, Store } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducer from "./store/reducer";
import App from './App';


const store: Store<any, any> & {
  dispatch: any
} = createStore(reducer, applyMiddleware(thunk));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider direction='rtl'>
    <Provider store={store}>
      <App />
    </Provider>,
  </ConfigProvider>
);
