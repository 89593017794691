import * as actionTypes from "./actionTypes";

const initialState: any = {
    user: {
        name: '',
        phone: '',
        _id: '',
        description: '',
        picture: '',
        active: false,
        birthDay: Date.now,
        role: '',
        isSystem: false
    },
    countNotification: 0
};

const reducer = (
    state: any = initialState,
    action: any
): any => {
    switch (action.type) {
        case actionTypes.Init:
            return {
                ...state,
                user: action.user,
            }
        case actionTypes.ChangeCountNotification:
            return {
                ...state,
                countNotification: action.countNotification,
            }
    }
    return state
}

export default reducer;