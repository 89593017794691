import { Alert, Avatar, Button, Checkbox, Form, Input, message, Modal, Space, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl, getApiUrl, headerConfig } from '../../../../../Helpers';
import { ISlider } from '../../../../../Models';

interface IProps {
    getData: () => void,
    open: boolean,
    setOpen: (value: boolean) => void,
    record: ISlider
}

const CreateSlider = ({ getData, open, setOpen, record }: IProps) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading , setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            if (record._id !== '') {
                form.setFieldsValue(record);
            }
            else {
                form.resetFields();
                setFileList([]);
            }
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    }

    const hideModal = () => {
        setOpen(false);
    }

    const handleSave = () => {

        form.validateFields().then(() => {
            setLoading(true);
            if (fileList[0]) {
                form.setFieldValue("picture", fileList[0].originFileObj);
            }
            const headerRequest = headerConfig();
            const formData = new FormData();
            const values = form.getFieldsValue();
            formData.append("title", values.title.trim());
            formData.append("description", values.description || ' ');
            formData.append("picture", values.picture || ' ');
            formData.append("active", values.active || false);
            formData.append("link", values.link || ' ');
            axios({
                url: getApiUrl(`/slider/${record._id}`),
                method: record._id === '' ? 'post' : 'put',
                data: formData,
                headers: headerRequest.headers
            }).then(response => {
                setLoading(false);
                message.success('عملیات با موفقیت انجام شد.');
                form.resetFields();
                setFileList([]);
                hideModal();
                getData();
            }).catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token');
                    return navigate('/login');
                }
                message.error(error.response.data.message);
                setLoading(false);
            });
        });
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {    
        if (newFileList[0].type?.indexOf("image") !== -1)
            return setFileList(newFileList);
        message.error('اجازه آپلود این فایل رو ندارید . فقط باید فرمت عکس وارد کنید.')
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    }

    return <>
        <Button onClick={showModal}>
            + ایجاد اسلایدر جدید
        </Button>
        <Modal width={800}
            title={record._id === '' ? '+ ایجاد اسلایدر جدید' : `ویرایش اسلایدر (${record.title})`}
            open={open}
            onOk={handleSave}
            onCancel={hideModal}
            footer={<div><Button onClick={handleSave} type='primary' loading={loading}>{record._id === '' ? 'ثبت' : 'ویرایش'}</Button></div>}
            maskClosable={false}
        >
            <Form name="createSlider"
                form={form}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20}}
                autoComplete="off">
                <Form.Item
                    label="عنوان اسلایدر"
                    name="title"
                    rules={[{ required: true, message: 'مقدار عنوان اسلایدر نمیتواند خالی باشد.' }]}
                >
                    <Input placeholder='عنوان اسلایدر' />
                </Form.Item>
                <Form.Item
                    label="لینک اسلایدر"
                    name="link"
                >
                    <Input placeholder='لینک اسلایدر' />
                </Form.Item>
                <Form.Item
                    label="توضیحات"
                    name="description"
                >
                    <TextArea placeholder="توضیحات" autoSize />
                </Form.Item>
                <Form.Item name="active" valuePropName="checked" wrapperCol={{ offset: 4, span: 20 }}>
                    <Checkbox>فعال / غیرفعال</Checkbox>
                </Form.Item>
                <Form.Item
                    label="آپلود عکس"
                    name="picture"
                >
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                    >
                        {fileList.length < 1 && '+ بارگزاری عکس'}
                    </Upload>
                    {record.picture && <Space>
                        <Alert type="warning" message="اگر عکسی آپلود کنید جایگزین عکس قبلی میشود." />
                        <Avatar src={`${baseUrl}/${record.picture}`} size={64} />
                    </Space>}
                </Form.Item>
            </Form>
        </Modal>
    </>

}

export default CreateSlider;